<template>
    <div>
        <create-dialog :value="dialog"
        @close-dialog="dialog=false, item=null"
        :item="item" @fetchData="fetchData" @setSelect="setSelect"></create-dialog>
        <v-dialog v-model="hanghoaDialog" width="600px">
            <v-card>
                <v-card-title class="mx-4">Danh sách hàng hóa 
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="success" small @click="setHangHoa">Chọn</v-btn> -->
                </v-card-title>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select :items="[
                                {text: 'Mã HH', value: 'No_'},
                                {text: 'Tên HH', value: 'Name'},
                                {text: 'Mô tả', value: 'Description'},
                            ]" outlined dense hide-details persistent-placeholder label="Loại" v-model="hanghoaFilterType"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Tìm kiếm" outlined dense hide-details persistent-placeholder v-model="hanghoaFilter"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-data-table :items="items2" :headers="headers2" >
                    <template v-slot:[`item.actions`]="{item}">
                        <v-btn x-small @click="setHangHoa(item)" color="success">Chọn</v-btn>
                    </template>
                </v-data-table>

            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="3" v-if="createPanel">
                <v-card >
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="mx-4">Tạo yêu cầu đặt hàng </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="teal" icon @click="createPanel=false"><v-icon>mdi-close</v-icon></v-btn>
                        
                    </v-toolbar>
                    <v-card-subtitle><b>USER ID:</b> {{USER_ID}} 
                    </v-card-subtitle>
                    <v-card-text >
                        <v-form id="dathang_form" ref="dathang_form" v-model="dathangFormValid" @submit.prevent="addDatHang" >
                            <v-row>
                                <v-col cols="12">
                                    <v-select :items="chinhanhs" label="Chi nhánh dự trù" outlined dense hide-details="auto" persistent-placeholder
                                    v-model="dathangForm.chinhanh_dutru"
                                    :rules="[
                                            v => !!v || 'Nhập dữ liệu']"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox hide-details label="Mặt hàng không có trên hệ thống" v-model="hanghoaOutter" dense></v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Tên hàng hóa" :readonly="!hanghoaOutter" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.tenhang"
                                    :rules="[
                                            v => !!v || 'Nhập dữ liệu']"
                                            ></v-text-field>
                                </v-col>
                                <v-col cols="6" v-if="hanghoaOutter">
                                    <v-file-input v-model="image" outlined dense hide-details="auto" @change="handleFileChange" label="Hình ảnh" persistent-placeholder>
                                    </v-file-input>
                                </v-col>
                                <v-col cols="6" v-if="!hanghoaOutter">
                                    <v-text-field label="Mã hàng hóa" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.mahanghoa"
                                    @change="maHangHoaOnChange"
                                    clearable
                                    :rules="[
                                            v => !!v || 'Nhập dữ liệu']"
                                    >
                                        <template v-slot:[`prepend`]="">
                                            <v-btn icon small @click="hanghoaDialog=true"><v-icon>mdi-magnify</v-icon></v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field readonly label="Tồn kho" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.tonkho_hethong"></v-text-field>
                                </v-col>
                                <v-col cols="6" v-if="hanghoaOutter">
                                    <v-text-field tabindex="-1" label="Đơn vị tính" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.dvt"
                                    :rules="[
                                            v => !!v || 'Nhập dữ liệu']" 
                                    @change="convertVietnameseDvt"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" v-if="!hanghoaOutter">
                                    <v-select label="Đơn vị tính" :items="hanghoaUnits" item-text="Code" item-value="Code" 
                                    outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.dvt"
                                    :rules="[
                                            v => !!v || 'Nhập dữ liệu']"
                                    >
                                    
                                        
                                        <template v-slot:[`item`]="{item}">
                                            {{ item.Code == "Nhảy lò cò" ? sendErr(item.Code) : item.Code }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field type="number" label="Số lượng dự trù" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.soluong_dutru"
                                    :rules="[
                                            v => !!v || 'Nhập dữ liệu']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" md="12">
                                    <v-text-field label="Loại hàng" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.loai_hang"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="12">
                                    <v-text-field label="Loại yêu cầu" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.loai_yeucau"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Trung bình bán" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.trungbinh_ban"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field type="date" label="Ngày giao cho khách" outlined dense hide-details="auto" persistent-placeholder v-model="dathangForm.ngay_giaokhach"></v-text-field>
                                </v-col>
                                
                                <v-col cols="12">
                                    <v-textarea label="Ghi chú" rows="2" outlined hide-details="auto" placeholder="Nhập ghi chú" persistent-placeholder v-model="dathangForm.ghichu_dathang">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" type="submit" form="dathang_form" block>Tạo yêu cầu đặt hàng</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" :md="`${createPanel?'9':'12'}`">
                <v-card>
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="mx-4">Danh sách phiếu</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="teal" outlined v-if="!createPanel" @click="createPanel=true">
                            Tạo phiếu
                        </v-btn>
                    
                    </v-toolbar>
                    
                    <v-card-title>
                        <v-row justify="start">
                            <v-col cols="12" md="4">
                                <v-text-field type="date" hide-details outlined dense v-model="filterFromDate" label="Từ ngày" ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <!-- todate -->
                                <v-text-field type="date" hide-details outlined dense v-model="filterToDate" label="Từ ngày" ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <!-- status -->
                                <v-select clearable :items="statuses" outlined hide-details v-model="filterStatus" dense label="Trạng thái">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select clearable :items="chinhanhs" outlined hide-details v-model="filterChiNhanh" dense label="Chi nhánh"></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select label="ASM duyệt ?" :items="[
                                    {text: 'Cần ASM duyệt', value: 1},
                                    {text: 'Không bắt buộc ASM duyệt', value: 0},
                                ]" v-model="filterKhongHoaDon" hide-details outlined dense clearable persistent-placeholder></v-select>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-text-field v-model="globalSearch" hide-details outlined dense full-width
                                    placeholder="Tìm kiếm" persistent-placeholder label="Tìm mã phiếu" ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" md="2">
                                <v-select v-model="filterStatus" hide-details outlined dense :items="['Mới', 'Đã nhận', 'Hoàn thành', 'Chối từ']" clearable
                                    placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm mã phiếu" ></v-select>
                            </v-col> -->
                            <!-- <v-col cols="12" md="2">
                                <v-text-field v-model="search" hide-details outlined dense
                                    placeholder="Tìm kiếm tên, chi nhánh" persistent-placeholder label="Tìm chi nhánh" ></v-text-field>
                            </v-col> -->
                            <!-- <v-col cols="12" md="2">
                                <v-text-field v-model="filterDepartment" hide-details outlined dense
                                    placeholder="Tìm kiếm chi nhánh" persistent-placeholder label="Tìm kiếm chi nhánh" ></v-text-field>
                            </v-col> -->
                        </v-row>
                    </v-card-title>
                </v-card>
                <v-row class="mt-5">
                    <v-col cols="12">
                        <v-card>
                            <!-- <v-card-title>Danh sách bộ câu hỏi</v-card-title> -->
                            <v-data-table :items="items1" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                            :search="globalSearch" >
                                <template v-slot:[`item.actions`]="{item}" >
                                    <v-menu dense
                                        left
                                        bottom offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" x-small
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Thao tác<v-icon>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            
                                            <v-list-item @click="edit(item.id)" dense>
                                                <v-list-item-icon>
                                                    <v-icon color="primary">mdi-cursor-text</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Chi tiết </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="exportTemplate(item)" dense>
                                                <v-list-item-icon>
                                                    <v-icon color="green">mdi-microsoft-excel</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Xuất file</v-list-item-title>
                                            </v-list-item>
                                            
                                            <v-list-item @click="publish(item.id)" dense>
                                                <v-list-item-icon>
                                                    <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                                <v-list-item-icon>
                                                    <v-icon color="error">mdi-delete</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Xóa</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- <edit-button @click="edit(item)"></edit-button>
                                    <delete-button @click="deleteItem(item)"></delete-button> -->
                                </template>
                                
                                <template v-slot:[`item.asmConfirm`]="{item}" >
                                    <v-checkbox v-if="item.KhongHoaDon==1 && isAsm" :input-value="item.TinhTrang == 'ASM đã duyệt'" @change="asmCheckConfirm(item, $event)"></v-checkbox>
                                </template>

                                <template v-slot:[`item.TinhTrang`]="{item}" >
                                    <v-btn small block
                                    :color="statusColors[item.TinhTrang]"
                                    dark
                                    >
                                    {{item.TinhTrang}}
                                    <!-- <v-icon right>mdi-menu-down</v-icon> -->
                                    </v-btn>
                                    <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                        <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                        <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                                    </p>
                                </template>
                                
                                <template v-slot:[`item.NganhHang`]="{item}">
                                    <b class="indigo--text">{{item.NganhHang}}</b>
                                </template>

                                <template v-slot:[`item.TenHang`]="{item}" >
                                    <a v-if="item.LinkAnh" :href="convertImage(item.LinkAnh)" target="_blank">
                                        <img  :src="convertImage(item.LinkAnh)" alt="" srcset="" width="120px">
                                    </a>
                                    <h4>{{item.TenHang}}</h4>
                                </template>
                                <template v-slot:[`item.GhiChu`]="{item}" >
                                    <b v-if="item.GhiChu">Ghi chú:</b> {{item.GhiChu}}
                                    <ul>
                                        <li v-if="item.LoaiHang">
                                            <b>Loại hàng: </b> {{item.LoaiHang}}
                                        </li>
                                        <li v-if="item.LoaiYeuCau">
                                            <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                        </li>
                                        <li v-if="item.TrungBinhBan">
                                            <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                        </li>
                                        <li v-if="item.NgayGiaoKhach">
                                            <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                        </li>
                                    </ul>
                                </template>

                                <template v-slot:[`item.deletePhieu`]="{item}" >
                                    <v-btn outlined small color="error" @click="deletePhieu(item.ID)"><v-icon small left>mdi-delete</v-icon> Xóa</v-btn>
                                </template>

                            </v-data-table>
                        </v-card>
                    </v-col>
                    
                </v-row>
            </v-col>

        </v-row>
        
        <!-- ************** BOTTOM SHEET  *************** -->
        <v-bottom-sheet v-model="bottomSheet" persistent max-width="600px" hide-overlay>
            <v-card
                v-if="item1SelectObj"
                class="" 
                height="300px"
            >
                <v-card-title v-if="bottomSheetType!='RETURN'" :class="`font-weight-bold ${bottomSheetType == 'ACCEPT'? 'green' : ( bottomSheetType == 'DENY'? 'red': '')}--text text--darken-2`"><v-spacer></v-spacer>{{bottomSheetType == 'ACCEPT'? 'Xác nhận' : ( bottomSheetType == 'DENY'? 'Từ chối': '')}} phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-title v-if="bottomSheetType=='RETURN'" class="font-weight-bold primary--text"> <v-spacer></v-spacer> Trả về phiếu phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-text v-if="bottomSheetType!='RETURN'">
                    <v-row>
                        <v-col cols="12" class="">
                            <label for="bottom_note" class="font-weight-bold text-left">Ghi chú</label>
                            <v-textarea id="bottom_note" rows="4" solo hide-details placeholder="Gõ enter để LƯU" v-model="note">

                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-3" outlined color="success" @click="setStatusNote" >
                    Lưu
                    </v-btn>

                    <v-btn class="mt-3" color="error" text @click="closeBottom" >
                    Đóng
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này? ID: {{targetItem}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteTemplate">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingDialog" width="300px" persistent>
            <v-card>
                <v-card-title class="text-center">
                    <v-spacer></v-spacer>
                    Đang xuất file
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="text-center">
                    <v-progress-circular
                    size="70"
                    indeterminate
                    color="orange"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateDialog from './Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import api from './services/api.js'

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    computed: {
        isAsm() {
            var result = false
            if(this.GROUP_MA) {
                if(this.GROUP_MA.length>0){
                    result = this.GROUP_MA.includes('TS_ASM')
                }
            }
            return result
        },
        selectedTemplate() {
            let rslt = this.item1Select.length>0 ? ` ${this.item1Select[0].id }`: ""

            return rslt
        },
        chinhanhs(){
            if(!this.Sources) return [] 
            var result =this.Sources.split("|") 
            if(result.length > 0){
                this.dathangForm.chinhanh_dutru = result[0]
            }
            return result
        },
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        },
        item1Select() {
            if(this.selectedTemplate != ""){
                this.getQuestions()
            } else{
                this.questions=[]
            }
        },
        filterFromDate() {
            this.getPhieu()
        },
        filterToDate() {
            this.getPhieu()
        },
        hanghoaFilterType() {
            this.getHangHoa()
        },
        hanghoaFilter() {
            this.getHangHoa()
        },
        
        'dathangForm.dvt': function(newVal, oldVal) {
            if(this.hanghoaUnits.length >0){
                let selected = this.hanghoaUnits.filter(x=>x.Code == newVal)[0]
                let tonkho = selected['End Quantity'] ? selected['End Quantity'] : 0
                this.dathangForm.tonkho_hethong = tonkho
            }
        },
        hanghoaOutter() {
            this.hanghoaUnits = []
            this.dathangForm.mahanghoa=""
            this.dathangForm.soluong_dutru=""
            this.dathangForm.dvt=""
            this.dathangForm.tenhang=""
            // this.dathangForm.chinhanh_dutru=""
            this.dathangForm.tonkho_hethong=""
            // this.dathangForm.userid_tao=""

            this.dathangForm.base64_image=[]
            this.dathangForm.loai_hang= ''
            this.dathangForm.trungbinh_ban= ''
            this.dathangForm.loai_yeucau= ''
            this.dathangForm.ngay_giaokhach= null
        }
    },
    data () {
        return {
            TOKEN: null,
            USER_ID: null,
            GROUP_MA: null,
            Sources: null,
            month: moment().startOf("months").format("yyyy-MM"),
            statuses: ['Chờ xác nhận','Đã xác nhận','Không đặt được', 'ASM đã duyệt'],
            statusColors: {
                'Chờ xác nhận': 'blue',
                'Đã xác nhận': 'green',
                'Không đặt được': 'red',
                'ASM đã duyệt': 'pink',
            },
            bottomSheet: false,
            createPanel: true,
            bottomSheetType: "",
            note: "",

            item: null,

            item1SelectObj: null,
            item1Select: [],
            item2Select: [],
            items1:[],
            items2:[],
            search:"",

            filterCode:"", 
            filterDepartment:"", 
            
            filterFromDate:moment().startOf('months').format('YYYY-MM-DD'),
            filterToDate: moment().endOf('months').format('YYYY-MM-DD'),
            filterStatus:"",
            filterChiNhanh:"",
            filterKhongHoaDon:null,

            currentItem: "",

            loadingDialog: false,
            globalSearch:"",
            headers1: [
                // {text:"Thao tác", value: "actions", width:"100px"},
                {text: "Xóa phiếu", value: "deletePhieu", width:"100px", sortable: false},
                {text: "Tình trạng", value: "TinhTrang", width:"150px",
                    filter: value => {
                        if (!this.filterStatus) return true
            
                        return value.toUpperCase().includes(this.filterStatus.toUpperCase())
                    },
                },
                {text: "Asm X/N", value: "asmConfirm", width:"100px", sortable: false},
                {text: "Tên Asm", value: "TenASMDuyet", width:"100px", sortable: false},
                {text: "Ngày Asm X/N", value: "NgayASMDuyet", width:"100px", sortable: false},
                {text: "ID", value: "ID", width:"70px"},
                {text: "Ngành hàng", value: "NganhHang", width:"120px"},
                {text: "Mã HH", value: "MaHang", width:"70px"},
                {text: "Tên HH", value: "TenHang", width:"200px"},
                {text: "ĐVT", value: "DVT", width:"100px"},
                {text: "Ghi chú", value: "GhiChu", width:"150px"},
                {text: "Ngày dự trù", value: "NgayDuTruStr", width:"120px"},
                {text: "Số lượng dự trù", value: "SoLuongDuTru", width:"100px"},
                {text: "Tồn kho", value: "TonKhoHeThong", width:"100px"},
                {text: "Loại hàng", value: "LoaiHang"},
                {text: "Trung bình bán", value: "TrungBinhBan"},
                {text: "Loại yêu cầu", value: "LoaiYeuCau"},
                {text: "Ngày giao khách", value: "NgayGiaoKhach"},
                {text: "Cần ASM duyệt", value: "KhongHoaDon",
                    filter: value => {
                        console.log(value, this.filterKhongHoaDon)
                        if(this.filterKhongHoaDon == null) return true
                        return value == this.filterKhongHoaDon
                    },
                },
                

                // {text: "Ghi chú", value: "note", width:"250px"},
                // {text: "Ghi chú chấp nhận dự trù", value: "DuTruDuoc", width:"250px"},
                // {text: "Ghi chú từ chối dự trù", value: "DuTruKhongDuoc", width:"250px"},
                // {text: "NgayDuTru", value: "NgayDuTru", width:"100px"},
                {text: "Chi nhánh dự trù", value: "ChiNhanhDuTru", width:"100px",
                    filter: value => {
                        if (!this.filterChiNhanh) return true
            
                        return value.toUpperCase().includes(this.filterChiNhanh.toUpperCase())
                    },
                },
                {text: "Người tạo", value: "TenNguoiTao", width:"100px"},
                {text: "Người cập nhật", value: "TenNguoiCapNhat", width:"100px"},
                {text: "Người xác nhận", value: "TenNguoiXacNhan"},

                // {text: "LinkAnh", value: "LinkAnh", width:"100px"},
            ],
            
            hanghoaDialog:false,
            hanghoaFilterType: "Name",
            hanghoaFilter: "",
            hanghoaUnits:[],
            hanghoaOutter:false,
            //dathang form
            dathangFormValid:false,
            dathangForm: {
                mahanghoa:"",
                soluong_dutru:"",
                dvt:"",
                tenhang:"",
                chinhanh_dutru:"",
                tonkho_hethong:"",
                userid_tao:"",
                base64_image:[],
                ghichu_dathang:"",
                loai_hang: '',
                trungbinh_ban: '',
                loai_yeucau: '',
                ngay_giaokhach: null,
            },
            image: null,
            imageData: {
                data_image: "",
                name:"",
                extension: ""
            },

            headers2: [
                {text:"", value: "actions", width:"5rem"},
                {text:"Mã", value: "No_", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Tên", value: "Name", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Mô tả", value: "Description 2", width:"7rem"}
            ],

            questionTypes: [
                {text: "Tự luận", value: "TEXT"},
                {text: "Lựa chọn", value: "OPTION"},
                {text: "Nhiều lựa chọn", value: "MULTI_OPTION", disabled: true},
            ],
            question:{
                type: "OPTION",
                name:"",
            },
            questions:[],

            answerHeader:[
                {text:"Câu trả lời", value:"name"},
                {text:"Xóa", value:"actions", width: 150}

            ],
            answers: [],
            answer:"",

            dialogDelete: false,
            targetItem: null,
            dialog: false,
            questionDialog:false,
        }
    },
    methods: {
        
        sendErr(value) {
            var result = window.confirm("Bạn vui lòng reload lại trang")
            if(result){
                location.reload()
            }
            console.log(value)
            // alert("Bạn vui lòng reload lại trang")
            return "Bạn vui lòng reload lại trang"
        },
        handleFileChange(file) {
            //reset
            const filename = file.name;
            const extension = filename.split('.').pop();

            this.imageData.name="xxxx";
            this.imageData.extension = extension

            //read base64
            const reader = new FileReader();
            
            reader.onload = (e) => {
                this.imageData.data_image = e.target.result.split(',')[1];
                console.log(e.target.result)
            };
            reader.readAsDataURL(file);
        },
        convertVietnamese(text) {
            var diacriticMap = {
                    'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
                    'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ặ': 'a',
                    'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
                    'đ': 'd',
                    'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
                    'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
                    'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
                    'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
                    'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
                    'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
                    'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
                    'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
                    'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
                    'À': 'A', 'Á': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
                    'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
                    'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
                    'Đ': 'D',
                    'È': 'E', 'É': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
                    'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
                    'Ì': 'I', 'Í': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
                    'Ò': 'O', 'Ó': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
                    'Ô': 'O', 'Ồ': 'O', 'Ố': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
                    'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
                    'Ù': 'U', 'Ú': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
                    'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
                    'Ỳ': 'Y', 'Ý': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y'
                };

                return text.replace(/[^\u0000-\u007E]/g, function(a) {
                    return diacriticMap[a] || a;
                });
        },
        convertVietnameseDvt(value){
            this.dathangForm.dvt = this.convertVietnamese(value)
        }, 
        convertImage(data) {
            const imgPrefix = 'https://storage.googleapis.com/tshrm-bucket/Uploads/DatHang/'
            if(data)
            {
                let arr = JSON.parse(data)
                if(arr.length>0){
                    let imgObj = arr[0]
                    return imgPrefix+imgObj['0']
                }
                return ''
            }
            return ''
        },

        fetchData() {
            return this.apiService.getTemplates().then(d => {
                this.items1 = d.data
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        publish(item) {
            this.apiService.publishTemplate(item).then(() => this.fetchData())
        },

        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        //template
        openBottom(item, status, event) {
            this.item1SelectObj = item
            // if(status == 'Đã xác nhận' || status == 'Không đặt được')
            this.bottomSheet = true;
            if(status == "Đã xác nhận")
                this.bottomSheetType = "ACCEPT"
            if(status == "Không đặt được")
                this.bottomSheetType = "DENY"
            if(status == "Chờ xác nhận"){
                this.bottomSheetType = "RETURN"
            }
            console.log(item, event)
        },
        closeBottom() {
            this.bottomSheet = false
            this.bottomSheetType = ''
            this.note = ''
        },

        deleteTemplate() {
            return this.apiService.deleteTemplate(this.targetItem).then(()=> {
                this.targetItem=undefined
                this.dialogDelete = false
                this.fetchData()
            })
        },
        
        exportTemplate(item){
            try {
                this.loadingDialog = true
                return http.get("surveys/"+item.id+"/export", {
                    params: {
                        month: moment(this.month).format('YYYY-MM-DD')
                    },
                    responseType: 'blob',
                }).then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `EXPORT_KHAO_SAT${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }).finally(()=> {
                    this.loadingDialog=false
                });
            } catch(e){
                alert(e)
            }
        },

        ///QUESTION
        closeQuestion() {
            this.questionDialog = false
            this.answers = []
            this.answer = ""
            this.question.name = ""
            this.question.type = "OPTION"
        },
        getQuestions() {
            try{
                return this.apiService.getQuestions(this.selectedTemplate).then(d => {
                    this.questions = d.data
                })
            }catch(e)
            {
                alert(e)
            }
        },
        saveQuestion(){
            let mappedAnswer = this.answers.map(x=>x.name)
            let postData = {
                name: this.question.name,
                type: this.question.type,
                answers: mappedAnswer
            }
            return this.apiService.postQuestion(this.selectedTemplate, postData).then(() => {
                this.closeQuestion()
                return this.getQuestions()
            })
        },
        deleteQuestion(id) {
            return this.apiService.deleteQuestion(this.selectedTemplate, id).then(() => {
                return this.getQuestions()
            })
        },
        changeQuestionOrder(id, mode) {
            return this.apiService.changeQuestionOrder(this.selectedTemplate, id, mode).then(() => {
                return this.getQuestions()
            })
        },
        /// ANSWER
        addAnswer() {
            this.answers.push({name: this.answer});
            this.answer="";
        },
        deleteAnswer(index) {
            // let index = this.answers.findIndex(obj => obj.name === name);
            if (index !== -1) {
            let removedObject = this.answers.splice(index, 1); // Remove the object from the array
            } else {
                alert('Object not found in the array.');
            }
        },
        
        getToken() {
            let userJson = localStorage.getItem("user")
            let user = JSON.parse(userJson)
            if(user){
                //NS0012 ma CM
                // ma asm NS0138
                let employeeCode = 'NS2977'
                if(user.employeeCode != 'NV05039')
                    employeeCode = user.employeeCode
                return this.apiService.getToken(employeeCode).then(d => {
                    this.TOKEN = d.token
                    this.USER_ID = d.UserID
                    this.GROUP_MA = d.GroupMa
                    this.Sources = d.sourceCode
                    this.dathangForm.userid_tao = d.UserID
                    // this.dathangForm.chinhanh_dutru = d.sourceCode
                })
            }
        },
        getPhieu() {
          return this.apiService.getPhieu(this.TOKEN, this.USER_ID, this.filterFromDate, this.filterToDate).then(d => {
            console.log("******",d.ds_dathang)
            this.items1 = d.ds_dathang.filter(x=>this.Sources.includes(x.ChiNhanhDuTru) ).sort((a, b) => a.KhongHoaDon - b.KhongHoaDon)
          })
        },

        deletePhieu(id) {
            return this.apiService.deletePhieu(id, this.USER_ID).then(() => this.getPhieu())
        }, 
        getHangHoa() {
            return this.apiService.getHangHoa(this.hanghoaFilterType, this.hanghoaFilter).then(d => {
                console.log(d)
                this.items2 = d
            })
        },
        getHangHoaUnit() {
            this.hanghoaUnits = []
            this.dathangForm.dvt = ''
            this.dathangForm.tonkho_hethong = 0
            return this.apiService.getHangHoaUnit(this.dathangForm.mahanghoa, this.USER_ID).then(d => {
                this.hanghoaUnits = d.data
                return true
            })
        },
        setHangHoa(item) {
            // console.log(this.item2Select[0])
            this.dathangForm.mahanghoa = item.No_
            this.dathangForm.tenhang = item.Name
            this.getHangHoaUnit().then(()=> {this.hanghoaDialog = false})

            // reset filter
            this.hanghoaFilter = '',
            this.hanghoaFilterType='Name'
        },

        //SUBMIT
        asmCheckConfirm(value, event) {
            console.log(value,event)
            this.apiService.asmConfirm({
                "ID": value.ID,
                "user_capnhat": this.USER_ID,
                "asm_duyet": this.USER_ID,
                "tinhtrang_asm": "ASM đã duyệt"
            }).then(() => this.getPhieu())
        },

        addDatHang() {
            this.$refs.dathang_form.validate();
            // alert(this.dathangFormValid)
            if(this.dathangFormValid){
                // alert("true")
                this.dathangForm.base64_image.push(this.imageData)
                this.dathangForm.tonkho_hethong = this.hanghoaOutter ? 0: this.dathangForm.tonkho_hethong

                if(this.hanghoaOutter == false)
                    this.dathangForm.base64_image = null

                console.log(this.dathangForm)
                return this.apiService.postDatHang(this.dathangForm).then(() => {
                    this.getPhieu()
                }).finally(() =>{
                    // reset
                    this.hanghoaUnits = []
                    this.dathangForm.mahanghoa =""
                    this.dathangForm.soluong_dutru =""
                    this.dathangForm.dvt =""
                    this.dathangForm.tenhang =""
                    this.dathangForm.chinhanh_dutru =""
                    this.dathangForm.tonkho_hethong =""
                    // this.dathangForm.userid_tao =""
                    this.dathangForm.base64_image =[]
                    this.dathangForm.ghichu_dathang=""
                    this.dathangForm.loai_hang= ''
                    this.dathangForm.trungbinh_ban= ''
                    this.dathangForm.loai_yeucau= ''
                    this.dathangForm.ngay_giaokhach= null
                    
                    //reset fileData
                    this.imageData.data_image = ""
                    this.imageData.name = ""
                    this.imageData.extension = ""
                    this.image = null
                })
            }else {
                // alert("false")
            }
        },

        // getHang
        maHangHoaOnChange(value) {
            this.getHangHoaUnit()
            return this.apiService.getHangHoa("No_", value).then(d => {
                this.dathangForm.tenhang = d.filter(x=>x.No_ == value)[0].Name
            }).catch(e => {
                console.log(e)
                this.dathangForm.tenhang=''
            })
        },
        
        setStatusNote() {
            if(this.item1SelectObj){
                if(this.bottomSheetType == "ACCEPT")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'ACCEPT').then(() => {
                            return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Đã xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                return this.getPhieu()
                            })
                        )
                    })
                if(this.bottomSheetType == "DENY")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'DENY').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Không đặt được").then(() => 
                        this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(()=>{
                            this.closeBottom()
                            return this.getPhieu()
                            })
                        )
                    })
                if(this.bottomSheetType == "RETURN"){
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Chờ xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                this.getPhieu()
                            })
                        )
                    })
                }
            }
            return true
        },

    },

    apiService: null,
    created() {
        this.apiService = new api()
        this.getToken().then(()=> {
            this.getHangHoa()
            this.getPhieu()
        })
    },
    mounted () {
      // this.fetchData()
      
    }
}
</script>