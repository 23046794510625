import Base from '@/views/layouts/SubLayouts/Base.vue';
import Home from '@/views/Home.vue';
import Enrollment from '@/views/HR/Enrollment/Index.vue';
import Profile from '@/views/Account/Profile.vue';
const News = () => import(/* webpackChunkName: "News" */ '@/views/News/Index.vue');
const NewsDetail = () => import(/* webpackChunkName: "NewsDetail" */ '@/views/News/Detail.vue');
const NewsCategory = () => import(/* webpackChunkName: "NewsCategory" */ '@/views/News/Category.vue');

//*********** Request */
import Request from '@/views/Request/Index.vue'
import LeaveRequest from '@/views/Request/Leave/Index.vue';
import LongLeaveRequest from '@/views/Request/LongLeave/Index';
import BusinessRequest from '@/views/Request/Business/Index.vue';
import OvertimeRequest from '@/views/Request/Overtime/Index.vue';
import OvertimeBooking from '@/views/Request/OvertimeBooking/Index.vue';
import PropertyRequest from '@/views/Request/PropertyRequest/Index.vue';
import PropertyRequestConfirm from '@/views/Request/PropertyRequestConfirm/Index.vue';
import QuitRequest from '@/views/Request/QuitRequest/Index.vue';
import EmployeeReport from '@/views/HR/EmployeeReport/Index'

import Todo from '@/views/Request/Todo/Index.vue';

//b/c Hinh anh
import ImageReport from '@/views/HR/ImageReport/Index.vue';

import EmployeeSalary from '@/views/Base/Salary/Index.vue';
import FileManager from '@/views/Base/FileManager/Index.vue';

// Khảo sát
import SurveyEmployee from '@/views/Base/Survey/Index.vue';
import SurveyEmployeeDetail from '@/views/Base/Survey/Detail.vue';

//IT rating
import ItRating from '@/views/Base/ItRating/Index.vue';

// ****** SUPPORT ****
import Support from '@/views/Support/Index.vue'

// DAT HANG
import Dathang from '@/views/Base/DatHang/Index';

// employee family
import EmployeeFamily from '@/views/Base/EmployeeFamily/Index';
import EmployeeFamilyCreate from '@/views/Base/EmployeeFamily/Create';

// employee update info
import EmployeeInfoUpdate from '@/views/Base/EmployeeInfoUpdate/Index';
import EmployeeInfoUpdateCreate from '@/views/Base/EmployeeInfoUpdate/Create';
import ItSupport from '@/views/Base/ItSupport/Index';
import InfoUpdate from '@/views/Base/InfoUpdate/Index';

import OcSupport from '@/views/Base/OcSupport/Index';



export default [
    {
      path: 'base',
      name: 'Base',
      component: Base,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      },
      children:[
        {
          path: 'ItSupport',
          name: 'ItSupport',
          component: ItSupport,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
        
        {
          path: 'InfoUpdate',
          name: 'InfoUpdate',
          component: InfoUpdate,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
        
        {
          path: 'OcSupport',
          name: 'OcSupport',
          component: OcSupport,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
        //employee family
        {
          path: 'employee-family',
          name: 'EmployeeFamily',
          component: EmployeeFamily
        },
        {
          path: 'employee-family/create',
          name: 'EmployeeFamilyCreate',
          component: EmployeeFamilyCreate
        },
        {
          path: 'employee-family/edit/:id',
          name: 'EmployeeFamilyEdit',
          component: EmployeeFamilyCreate
        },

        //employee update info
        {
          path: 'employee-info-update',
          name: 'EmployeeInfoUpdate',
          component: EmployeeInfoUpdate
        },
        {
          path: 'employee-info-update/create',
          name: 'EmployeeInfoUpdateCreate',
          component: EmployeeInfoUpdateCreate
        },
        {
          path: 'employee-info-update/edit/:id',
          name: 'EmployeeInfoUpdateEdit',
          component: EmployeeInfoUpdateCreate
        },

        {
          path: 'dat-hang',
          name: 'BaseDatHang',
          component: Dathang,
        },
        {
          path: 'survey-employee',
          name: 'SurveyEmployee',
          component: SurveyEmployee,
        },
        {
          path: 'it-rating',
          name: 'ItRating',
          component: ItRating,
        },
        {
          path: 'survey-employee/detail/:id',
          name: 'SurveyEmployeeDetail',
          component: SurveyEmployeeDetail,
        },
        {
          path: 'external-filemanager',
          name: 'ExternalFileManager',
          component: FileManager,
          // beforeEnter(to, from, next) {
          //     // Put the full page URL including the protocol http(s) below
          //     let token = localStorage.getItem("token")
          //     window.location.replace("https://insite.trungsonpharma.com/faq?token="+token)
          // }
        },
        {
          path: 'home',
          alias:'',
          name: 'Home',
          component: Home,
          meta: { 
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                }
              },
            ],
          },
        },
        
        {
          path: 'news/category/:category',
          name: 'NewsCategory',
          component: NewsCategory,
          meta: route => (
            { 
              category: `${route.params.category}`,
              breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Tin tức nội bộ',
                  disabled: false,
                  to: {
                      name: "News",
                      }
                  },
              ],
            })
        },
        {
          path: 'news',
          name: 'News',
          component: News,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Tin tức nội bộ',
                disabled: true,
                to: {
                    name: "News",
                    }
                },
            ],
          },
        },
        {
          path: 'news/:id',
          name: 'NewsDetail',
          component: NewsDetail,
          meta: route => (
            { 
              id: `${route.params.id}`,
              breadcrumbs: [
                  {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                  },
                  {
                    text: 'Tin tức nội bộ',
                    disabled: false,
                    to: {
                        name: "News",
                        }
                  },
              ],
            })
        },
        {
          path: 'enrollments',
          name: 'Enrollment',
          component: Enrollment,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Chấm công',
                disabled: true,
                to: {
                    name: "Enrollment",
                    }
                },
            ],
          },
        },
        {
          path: '/profile',
          name: 'Profile',
          component: Profile,
          meta:{
            breadcrumbs: [
              {
              text: 'Dashboard',
              disabled: false,
              to: {
                  name: "Home",
                  }
              },
              {
              text: 'Hồ sơ nhân viên',
              disabled: true,
              to: {
                  name: "Profile",
                  }
              },
          ],
          }
        },

        {
          path: '/employee-salary',
          name: 'EmployeeSalary',
          component: EmployeeSalary,
        },
        
        // ******* REQUEST **********
        {
          path: "Request",
          component: Request,
          children:[
            {
              path: 'overtime',
              name: 'OvertimeRequest',
              component: OvertimeRequest,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Danh sách công tác ',
                  disabled: true,
                  to: {
                      name: "Business",
                      }
                  },
              ],
              }
            },
            
            {
              path: 'quit',
              name: 'QuitRequest',
              component: QuitRequest,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Đơn xin nghỉ việc',
                  disabled: true,
                  to: {
                      name: "Business",
                      }
                  },
              ],
              }
            },
            
            {
              path: 'property-request',
              name: 'PropertyRequest',
              component: PropertyRequest,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Danh sách phiếu công cụ dụng cụ',
                  disabled: true,
                  to: {
                      name: "PropertyRequest",
                      }
                  },
              ],
              }
            },

            //bcha
            {
              path: 'image-report',
              name: 'BaseImageReport',
              component: ImageReport,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Báo cáo hình ảnh',
                    disabled: true,
                    to: {
                        name: "BaseImageReport",
                        }
                    },
                ],
              }
            },
            
            {
              path: 'property-request-confirm',
              name: 'PropertyRequestConfirm',
              component: PropertyRequestConfirm,
              meta: {
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Danh sách tiếp nhận phiếu công cụ dụng cụ',
                  disabled: true,
                  to: {
                      name: "PropertyRequestConfirm",
                      }
                  },
              ],
              }
            },
            {
              path: 'overtime-booking',
              name: 'OvertimeBooking',
              component: OvertimeBooking,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Đăng kí tăng ca',
                  disabled: true,
                  to: {
                      name: "OvertimeBooking",
                      }
                  },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'leave',
              name: 'LeaveRequest',
              component: LeaveRequest,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Danh sách nghỉ phép ',
                    disabled: true,
                    to: {
                        name: "Leave",
                        }
                    },
                ],
              }
            },
            {
              path: 'business',
              name: 'BusinessRequest',
              component: BusinessRequest,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Đơn công tác',
                    disabled: true,
                    to: {
                        name: "Business",
                        }
                    }
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'long-leaves-rq',
              alias: ["request/long-leave"],
              name: 'LongLeaveRequest',
              component: LongLeaveRequest,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Quản lí off dài hạn ',
                    disabled: true,
                    to: {
                        name: "Contract",
                        }
                    },
                ],
              }
            },
            {
              path: 'support',
              name: 'Support',
              component: Support,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Hỗ trợ ticket',
                    disabled: true,
                    to: {
                        name: "Business",
                        }
                    }
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            {
              path: 'store-report',
              name: 'StoreReport',
              component: EmployeeReport,
              meta: { 
              }
            },
            {
              path: 'todo',
              name: 'Todo',
              component: Todo,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Báo cáo công việc',
                    disabled: true,
                    to: {
                        name: "Todo",
                        }
                    }
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            
          ]
        },
        
      ]
    },
    
  ]
